import { useCallback, useEffect } from 'react';
import { usePostHog } from 'posthog-js/react';

import type { PluginListenerHandle } from '@capacitor/core';
import { Capacitor } from '@capacitor/core';
import { isPlatform } from '@ionic/react';

import { useUserOrg } from '.';

import { checkHasNotificationToken } from '../components/AppUpdate/appUpdateSlice';
import { environment } from '../environment/environment';
import { isNative } from '../helpers/utils.helper';
import { getUsedNotification } from '../pages/Permissions/permissions.service';
import { initNativeNotification } from '../services/notification.service';
import { useAppDispatch, useAppSelector } from '../store';

export const useInitApp = () => {
  const dispatch = useAppDispatch();
  const posthog = usePostHog();
  const currentUser = useAppSelector((state) => state.login.currentUser);
  const token = useAppSelector((state) => state.login.authResult.token);
  const offlineSubmissionLocked = useAppSelector((state) => state.journal.offlineSubmissionLocked);
  const { isYale } = useUserOrg();
  const hasToken = Boolean(token);

  const submitSavedJournalsCallback = useCallback(async () => {
    const { submitSavedJournals } = await import('../services/offlineJournal.service');
    submitSavedJournals(dispatch, offlineSubmissionLocked, isYale, hasToken);
  }, [dispatch, hasToken, isYale, offlineSubmissionLocked]);

  const reRegisterNotificationToken = useCallback(async () => {
    if (getUsedNotification() && hasToken) {
      const hasNotificationToken = (await dispatch(checkHasNotificationToken())).payload;
      if (hasNotificationToken === false) {
        initNativeNotification();
      }
    }
  }, [dispatch, hasToken]);

  useEffect(() => {
    let submitSavedJournalsSetIntervalId: NodeJS.Timeout;
    if (isNative()) {
      submitSavedJournalsSetIntervalId = setInterval(submitSavedJournalsCallback, 120 * 1000);
    }

    return () => {
      clearInterval(submitSavedJournalsSetIntervalId);
    };
  });

  useEffect(() => {
    document.getElementById('loader')?.remove();

    let hideSplashScreenSetTimeoutId: NodeJS.Timeout;
    if (isNative()) {
      hideSplashScreenSetTimeoutId = setTimeout(async () => {
        const { SplashScreen } = await import('@capacitor/splash-screen');
        if (Capacitor.isPluginAvailable('SplashScreen')) {
          SplashScreen.hide();
        }
      }, 2000);

      if (isPlatform('ios')) {
        (async () => {
          const { Keyboard } = await import('@capacitor/keyboard');
          if (Capacitor.isPluginAvailable('Keyboard')) {
            await Keyboard.setAccessoryBarVisible({ isVisible: true });
          }
        })();
      }
    }

    return () => {
      clearTimeout(hideSplashScreenSetTimeoutId);
    };
  }, []);

  useEffect(() => {
    if (isNative() && hasToken) {
      initNativeNotification();
    }
  }, [hasToken]);

  useEffect(() => {
    let appListener: PluginListenerHandle;

    if (isNative()) {
      (async () => {
        const { App } = await import('@capacitor/app');
        if (Capacitor.isPluginAvailable('App')) {
          appListener = await App.addListener('appStateChange', async ({ isActive }) => {
            if (isActive) {
              reRegisterNotificationToken();
            }
          });
        }
      })();
    }

    return () => {
      appListener?.remove();
    };
  }, [reRegisterNotificationToken]);

  useEffect(() => {
    if (currentUser?.user_id) {
      posthog?.identify(currentUser.user_id, {
        user_id: currentUser.user_id,
        organization_id: currentUser.organization_id,
        region: currentUser.region === 'us-prod' ? 'US' : 'CA',
        platform: Capacitor.getPlatform(),
        version: environment.versionNumber,
      });
    } else {
      posthog?.reset();
    }
  }, [currentUser?.organization_id, currentUser?.region, currentUser?.user_id, posthog]);
};
