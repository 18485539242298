import { useEffect, useState } from 'react';
import type { ReactNode } from 'react';

import { isNative } from '../helpers/utils.helper';
import { getWebUserAsync } from '../pages/Login/Login.service';
import type { AuthResult } from '../pages/Login/loginSlice';
import { getVaultAuthResult, refreshToken, updateAuth } from '../pages/Login/loginSlice';
import { useAppDispatch } from '../store';

export const AuthPersist = ({ children }: { children: ReactNode }) => {
  const dispatch = useAppDispatch();
  const [ready, setReady] = useState(false);

  useEffect(() => {
    const getPersistedState = async () => {
      let storedAuthResult: AuthResult | undefined = undefined;

      if (isNative()) {
        const response = await dispatch(getVaultAuthResult());
        storedAuthResult = response.payload as AuthResult;
      } else {
        storedAuthResult = await getWebUserAsync();
      }

      if (storedAuthResult?.token) {
        dispatch(updateAuth(storedAuthResult));
        await dispatch(refreshToken({ history: null, isJournaling: false, storeAuth: true }));
      }

      setReady(true);
    };

    getPersistedState();
  }, [dispatch]);

  if (!ready) {
    return null;
  }

  return <>{children}</>;
};
