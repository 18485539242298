import { volumeHigh } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';

import { IonCol, IonContent, IonGrid, IonIcon, IonRow, IonText } from '@ionic/react';

import './cardQuestions.scss';

import CryingFace from '../../assets/emoji/crying-face.png';
import GrinningFace from '../../assets/emoji/grinning-face-with-smiling-eyes.png';
import NeutralFace from '../../assets/emoji/neutral-face.png';
import NotApplicable from '../../assets/emoji/not-applicable.png';
import PensiveFace from '../../assets/emoji/pensive-face.png';
import SlightlySmilingFace from '../../assets/emoji/slightly-smiling-face.png';

import { isNative } from '../../helpers/utils.helper';
import type { Question, QuestionValue } from '../../pages/Journal/journalSlice';
import { onMultiOptionPressed, onSingleOptionPressed } from '../../pages/Journal/journalSlice';
import { useAppDispatch, useAppSelector } from '../../store';

const alphabet = [...'abcdefghijklmnopqrstuvwxyz'];

const getEmoji = (icon: string) => {
  switch (icon) {
    case 'grinning-face-with-smiling-eyes.png':
      return GrinningFace;
    case 'slightly-smiling-face.png':
      return SlightlySmilingFace;
    case 'neutral-face.png':
      return NeutralFace;
    case 'pensive-face.png':
      return PensiveFace;
    case 'crying-face.png':
      return CryingFace;
    case 'not-applicable.png':
      return NotApplicable;
  }
};

const isSelected = (questionValue: QuestionValue, question: Question) => {
  if (question.type === 'single_select' || question.type === 'option') {
    return question.selected === questionValue.value;
  } else if (question.type === 'mutli_select' && Array.isArray(question.selected)) {
    return question.selected.some((val) => val === questionValue.value);
  }
};

const getEmojiName = (iconName: string) => {
  const [name] = iconName?.split('.');
  if (name) {
    return name.replace(/-/g, ' ').replace(/\d/g, '');
  } else {
    return 'emoji';
  }
};

export const JournalCardQuestion = ({ questionId }: { questionId: string }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const question = useAppSelector((state) =>
    state.journal.questions.find((question) => question.product_field_id === questionId),
  );
  const audioAlias = question?.audio_url_alias ? t(`Audio_question.${question?.audio_url_alias}`) : null;
  const audio = audioAlias ? new Audio(audioAlias) : null;

  const setQuestionValue = async (questionValue: number) => {
    if (questionValue === undefined || questionValue === null) {
      return;
    }

    if (question?.type === 'single_select') {
      //support option type
      dispatch(
        onSingleOptionPressed({
          selected: questionValue,
          product_field_id: questionId,
          selection_time: new Date(),
        }),
      );
    } else {
      dispatch(
        onMultiOptionPressed({
          selected: questionValue,
          product_field_id: question?.product_field_id,
          selection_time: new Date(),
        }),
      );
    }

    if (isNative()) {
      const { HapticsService } = await import('../../services/haptics.service');
      await HapticsService.hapticsImpactMedium();
    }
  };

  const playAudio = () => {
    if (null === audio || audio.readyState === audio.HAVE_NOTHING) return;

    if (audio.paused) {
      audio.play();
    }
  };

  return (
    <IonContent className="ion-no-padding">
      <IonGrid style={{ height: '100%' }}>
        <IonRow>
          <IonCol className="titleTextCol">
            <IonText className="titleText">
              {question && (
                <span
                  className="smallOrangeTextHTML"
                  dangerouslySetInnerHTML={{
                    __html: t(`fields.${question.long_name_alias}`),
                  }}
                />
              )}
              {audio ? (
                <IonIcon
                  aria-label="audio play click"
                  className="placeholderIcon"
                  style={{ marginBottom: -10 }}
                  size="large"
                  icon={volumeHigh}
                  onClick={playAudio}
                ></IonIcon>
              ) : null}
            </IonText>
          </IonCol>
        </IonRow>

        <div className="cardQuestionBody">
          {question?.values.map((questionValue, index) => {
            const selected = isSelected(questionValue, question);
            return (
              <IonRow
                aria-label={`${questionValue.name_alias} click`}
                key={questionValue.name_alias}
                className={selected ? 'cardBox clicked' : 'cardBox'}
                onClick={() => setQuestionValue(questionValue.value)}
              >
                <IonCol size="2" className="cardNumbering">
                  <IonText color={selected ? 'light' : 'primary'}>
                    {questionValue.icon ? (
                      <span className={selected ? 'boldEmoji' : 'normalEmoji'}>
                        <img alt={getEmojiName(questionValue.icon)} src={getEmoji(questionValue.icon)} />
                      </span>
                    ) : (
                      alphabet[index]
                    )}
                  </IonText>
                </IonCol>
                <IonCol className="cardQuestion">
                  <IonText color={selected ? 'light' : 'primary'}>{t(`fields.${questionValue.name_alias}`)}</IonText>
                </IonCol>
              </IonRow>
            );
          })}
        </div>
      </IonGrid>
    </IonContent>
  );
};
