import { isPlatform } from '@ionic/react';

export const sample = <T>(array: T[] | undefined) => {
  const length = array == null ? 0 : array.length;
  return array && length ? array[Math.floor(Math.random() * length)] : undefined;
};

export const isNative = () => (isPlatform('ios') || isPlatform('android')) && !isPlatform('mobileweb');

export const getCustomIcon = (icon: string) => {
  let src = `assets/icons/${icon}.svg`;
  if (process.env.NODE_ENV === 'test') {
    src = 'public/' + src;
  }
  const url = new URL(src, window.location.origin);
  return url.href;
};
