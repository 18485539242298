import { useEffect, useState } from 'react';
import { addDays, differenceInCalendarWeeks, endOfMonth, startOfMonth, startOfWeek } from 'date-fns';
import dayjs from 'dayjs';

import { getLanguage } from '../../pages/Preferences/languageSlice';

export type GroupedByMonth<T> = {
  groupName: string;
  translatedName: string;
  month: number;
  year: number;
  data: T[];
};

type WeekStartsOn = 0 | 1 | 2 | 3 | 4 | 5 | 6 | undefined;

const generateMatrix = (
  year: number | undefined,
  month: number | undefined,
  formatDay = (day: Date) => day,
  weekStartsOn: WeekStartsOn = 0,
  daysInWeek = 7,
) => {
  if (year === undefined || month === undefined) {
    return [];
  }

  const date = new Date(year, month);
  const startDay = startOfMonth(date);
  const lastDay = endOfMonth(date);
  const startDate = startOfWeek(startDay, { weekStartsOn });
  const rows = differenceInCalendarWeeks(lastDay, startDay, { weekStartsOn }) + 1;
  const cols = daysInWeek;
  const totalDays = rows * cols;

  return Array.from({ length: totalDays })
    .map((_, index) => addDays(startDate, index))
    .map((day) => (typeof formatDay === 'function' ? formatDay(day) : day))
    .reduce((matrix, _current, index, days) => {
      return index % cols === 0 ? [...matrix, days.slice(index, index + cols)] : matrix;
    }, [] as Date[][]);
};

export const useCalendarMatrix = (year: number | undefined, month: number | undefined) => {
  const [matrix, setMatrix] = useState(() => generateMatrix(year, month));

  useEffect(() => {
    setMatrix(generateMatrix(year, month));
  }, [year, month]);

  return matrix;
};

export const getDaysInitialAndValue = () => {
  const lng = getLanguage();
  if (lng?.includes('es')) {
    return [
      { day: 'D', value: 0 },
      { day: 'L', value: 1 },
      { day: 'M', value: 2 },
      { day: 'M', value: 3 },
      { day: 'J', value: 4 },
      { day: 'V', value: 5 },
      { day: 'S', value: 6 },
    ];
  }

  if (lng?.includes('fr')) {
    return [
      { day: 'D', value: 0 },
      { day: 'L', value: 1 },
      { day: 'M', value: 2 },
      { day: 'M', value: 3 },
      { day: 'J', value: 4 },
      { day: 'V', value: 5 },
      { day: 'S', value: 6 },
    ];
  }

  if (lng?.includes('cr')) {
    return [
      { day: 'a', value: 0 },
      { day: 'p', value: 1 },
      { day: 'nes', value: 2 },
      { day: 'nis', value: 3 },
      { day: 'new', value: 4 },
      { day: 'ney', value: 5 },
      { day: 'm', value: 6 },
    ];
  }

  return [
    { day: 'S', value: 0 },
    { day: 'M', value: 1 },
    { day: 'T', value: 2 },
    { day: 'W', value: 3 },
    { day: 'T', value: 4 },
    { day: 'F', value: 5 },
    { day: 'S', value: 6 },
  ];
};

export const getDaysInitial = () => {
  const lng = getLanguage();
  if (lng?.includes('es') || lng?.includes('fr')) {
    return ['D', 'L', 'M', 'M', 'J', 'V', 'S'];
  }

  if (lng?.includes('cr')) {
    return ['a', 'p', 'nes', 'nis', 'new', 'ney', 'm'];
  }

  return ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
};

const engShortMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const getMonthsShortName = () => {
  const lng = getLanguage();
  if (lng?.includes('es')) {
    return ['enero', 'feb', 'marzo', 'abr', 'mayo', 'jun', 'jul', 'agosto', 'set', 'oct', 'nov', 'dic'];
  }
  if (lng?.includes('fr')) {
    return ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Jun', 'Jul', 'Août', 'Sep', 'Oct', 'Nov', 'Déc'];
  }

  if (lng?.includes('cr')) {
    return [
      'kisêpêsim',
      'mikisêwipêsim',
      'niskipêsim',
      'ayikipêsim',
      'sâkipakâwipêsim',
      'pâskâwêhowipêsim',
      'paskopêsim',
      'ôhpahôwipêsim',
      'nôcêhtowipêsim',
      'pimahâmowipêsim',
      'iyihkopêwipêsim',
      'opâwâhcikinasêsipêsim',
    ];
  }

  return engShortMonths;
};

const getTranslatedDateInfo = (timestamp: string) => {
  const shortMonthNames = getMonthsShortName();
  const date = new Date(timestamp);
  const month = date.getMonth();
  const year = date.getFullYear();
  const name = `${shortMonthNames[month]} ${year}`;
  return { name, month, year };
};

export const formatByMonths = <
  T extends { timestamp: string; submission_timestamp?: string }, // submission_timestamp is here to handle offline entries
  U extends GroupedByMonth<T>,
>(
  data: T[],
): U[] => {
  if (!Array.isArray(data)) {
    return [];
  }

  const map = new Map<string, U>();

  for (const d of data) {
    const groupName = dayjs(d.timestamp ?? d.submission_timestamp).format('YYYY-MM');
    const { name, month, year } = getTranslatedDateInfo(d.timestamp ?? d.submission_timestamp);

    if (map.has(groupName)) {
      map.get(groupName)?.data.push(d);
    } else {
      map.set(groupName, { groupName, translatedName: name, month, year, data: [d] } as U);
    }
  }

  const sortedEntriesByMonth = [...map.values()].sort((a, b) => {
    if (a.year !== b.year) return b.year - a.year;
    return b.month - a.month;
  });

  return sortedEntriesByMonth;
};

const translateMonth = (dateStr: string) => {
  return dateStr.replace(/[A-Z][a-z]{2}/, (match) => {
    const translatedMonths = getMonthsShortName();
    const index = engShortMonths.findIndex((month) => month === match);
    return translatedMonths[index];
  });
};

export const dateFormatAndTranslate = (dateStr: string) => {
  const d = dayjs(dateStr);
  if (!d.isValid()) {
    return null;
  }

  return translateMonth(d.format('MMM D, YYYY'));
};

export const saveSelectedMonth = (selectedMonth: string) => {
  localStorage.setItem('selectedMonth', selectedMonth);
};

export const getSelectedMonth = () => {
  return localStorage.getItem('selectedMonth');
};
