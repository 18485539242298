import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

export enum TABS_MODAL {
  CHECKIN = 'checkIn',
  SETTINGS = 'settings',
  IDLE = 'IDLE',
  TOKEN_TIMEOUT = 'tokenTimeOut',
}

export enum TABS_TOAST {
  IDLE = 'IDLE',
  API_ERROR = 'API_ERROR',
  FORGOT_PWD_SENT = 'FORGOT_PWD_SENT',
}

type ErrorMessageAlias = 'loginPage.emailError' | 'loginPage.subtitle';

type TabSlice = {
  modal: TABS_MODAL;
  toast: TABS_TOAST;
  showInternetModal: boolean;
  loading: boolean;
  alertIsOpen: boolean;
  errorMessageAlias: ErrorMessageAlias;
};

const initialState: TabSlice = {
  modal: TABS_MODAL.IDLE,
  toast: TABS_TOAST.IDLE,
  showInternetModal: false,
  loading: false,
  alertIsOpen: false,
  errorMessageAlias: 'loginPage.emailError',
};

export const tabsSlice = createSlice({
  name: 'tabs',
  initialState,
  reducers: {
    showCheckIn: (state) => {
      state.modal = TABS_MODAL.CHECKIN;
    },
    showSettings: (state) => {
      state.modal = TABS_MODAL.SETTINGS;
    },
    showTokenTimeOut: (state) => {
      state.modal = TABS_MODAL.TOKEN_TIMEOUT;
    },
    closeModal: (state) => {
      state.modal = TABS_MODAL.IDLE;
    },
    showInternetError: (state, action: PayloadAction<boolean>) => {
      state.showInternetModal = action.payload;
    },
    showLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setErrorToast: (state, action: PayloadAction<TABS_TOAST>) => {
      state.toast = action.payload;
    },
    closeAlert: (state) => {
      state.alertIsOpen = initialState.alertIsOpen;
      state.errorMessageAlias = initialState.errorMessageAlias;
    },
    setErrorMessageAlias: (state, action: PayloadAction<ErrorMessageAlias>) => {
      state.alertIsOpen = true;
      state.errorMessageAlias = action.payload;
    },
  },
});

export const {
  showCheckIn,
  showSettings,
  closeModal,
  showInternetError,
  showTokenTimeOut,
  showLoading,
  setErrorToast,
  setErrorMessageAlias,
  closeAlert,
} = tabsSlice.actions;
