import type { AxiosError } from 'axios';
import axios from 'axios';

import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import type { EnglishTranslation } from '../../i18n';
import type { ErrorMessage } from '../../models/error';
import type { AsyncThunkConfig } from '../../models/slice';
import { RaygunErrorHandlerService } from '../../services/raygun.service';

const { logError } = RaygunErrorHandlerService();

export type Badge = {
  badge_alias: string;
  id: string;
  dates_earned: string[];
  icon: string;
  last_earned: string;
  name_alias: keyof EnglishTranslation['badges'];
  description_alias: keyof EnglishTranslation['badges'];
  earning_alias: keyof EnglishTranslation['badges'];
  times_earned: number;
  icon_url: string;
};

type BadgesSliceType = {
  badges: Badge[];
  selectedBadge: Badge | null;
  pageView: number;
};

export const fetchBadges = createAsyncThunk<Badge[], undefined, AsyncThunkConfig>(
  'badges/fetchBadges',
  async (_, thunkAPI) => {
    try {
      const online = navigator.onLine;
      if (online) {
        const response = (await axios.get('/v3_badges')) as Badge[];
        return response;
      }
      return [];
    } catch (e) {
      logError(e, ['badgesSlice', 'fetchBadges']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

const initialState: BadgesSliceType = {
  badges: [],
  selectedBadge: null,
  pageView: 0,
};

export const badgesSlice = createSlice({
  name: 'badges',
  initialState,
  reducers: {
    showDetail(state, action: PayloadAction<Badge>) {
      state.selectedBadge = action.payload;
      state.pageView = 1;
    },
    showList(state) {
      state.pageView = 0;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBadges.fulfilled, (state, action) => {
      state.badges = action.payload;
    });
  },
});

export const { showDetail, showList } = badgesSlice.actions;
