import type { AxiosError } from 'axios';
import axios from 'axios';

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { APIStatus } from '../../models/api';
import type { ErrorMessage } from '../../models/error';
import type { AsyncThunkConfig } from '../../models/slice';
import { RaygunErrorHandlerService } from '../../services/raygun.service';

const { logError } = RaygunErrorHandlerService();

export const fetchStreaks = createAsyncThunk<Streak, undefined, AsyncThunkConfig>(
  'streaks/fetchStreaks',
  async (_, thunkAPI) => {
    try {
      const response = (await axios.post('/v3_current_streak')) as Streak;
      return response;
    } catch (e) {
      logError(e, ['streakSlice', 'fetchStreaks']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

type Streak = {
  current_streak_days: number;
  last_7_days: Last7Days[];
};

type Last7Days = {
  date: string;
  day_of_week: string;
  did_journal: boolean;
  day_of_week_number: number;
};

type StreakSliceType = {
  streakApiStatus: APIStatus;
  streaks: Streak | undefined;
};

const initialState: StreakSliceType = {
  streakApiStatus: APIStatus.IDLE,
  streaks: undefined,
};

export const streakSlice = createSlice({
  name: 'streaks',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchStreaks.pending, (state, _action) => {
        state.streakApiStatus = APIStatus.PENDING;
      })
      .addCase(fetchStreaks.fulfilled, (state, action) => {
        state.streakApiStatus = APIStatus.FULFILLED;
        state.streaks = action.payload;
      });
  },
});

// export const {} = streakSlice.actions;
