import type { History } from 'history';

import { Capacitor } from '@capacitor/core';
import { Preferences } from '@capacitor/preferences';

import { environment } from '../../environment/environment';
import { isNative } from '../../helpers/utils.helper';
import { CryptoService } from '../../services/crypto.service';
import { RaygunErrorHandlerService } from '../../services/raygun.service';
import type { AppDispatch } from '../../store';
import { clearAllStates } from '../../store';
import { showTokenTimeOut } from '../tabs/tabsSlice';
import { AuthResult, removeVaultValue } from './loginSlice';

const { resetRaygunUser, logError } = RaygunErrorHandlerService();

export const didOnboard = () => {
  return localStorage.getItem('onboard') === 'true';
};

export const getWebUserAsync = async () => {
  if (!Capacitor.isPluginAvailable('Preferences')) {
    return new AuthResult();
  }

  const ret = await Preferences.get({ key: environment.authResultKey });
  const cryptedAuthResult = ret.value;
  if (cryptedAuthResult) {
    try {
      const decrypted = await CryptoService.decrypt(cryptedAuthResult, environment.cryptoKey, 'getWebUserAsync');
      const authResult: AuthResult = JSON.parse(decrypted);
      return authResult?.token ? authResult : new AuthResult();
    } catch (error) {
      logError(error, ['Login.servive', 'getWebUserAsync']);
      return new AuthResult();
    }
  }
  return new AuthResult();
};

export const removeWebUser = async () => {
  if (!Capacitor.isPluginAvailable('Preferences')) {
    return;
  }

  await Preferences.remove({ key: environment.authResultKey });
};

export const saveWebUser = async (user: AuthResult) => {
  if (!Capacitor.isPluginAvailable('Preferences')) {
    return;
  }

  if (user?.token) {
    const cryptedAuthResult = await CryptoService.encrypt(JSON.stringify(user), environment.cryptoKey, 'saveWebUser');
    await Preferences.set({
      key: environment.authResultKey,
      value: cryptedAuthResult,
    });
  }
};

export const getBiometricsEmailAsync = async () => {
  if (!Capacitor.isPluginAvailable('Preferences')) {
    return '';
  }

  const ret = await Preferences.get({ key: environment.biometricsEmailKey });
  const cryptedEmail = ret.value;
  if (cryptedEmail) {
    const decryptedEmail = CryptoService.decrypt(cryptedEmail, environment.cryptoKey, 'getBiometricsEmailAsync');
    return decryptedEmail;
  }
  return '';
};

export const removeBiometricsEmail = async () => {
  if (!Capacitor.isPluginAvailable('Preferences')) {
    return;
  }

  await Preferences.remove({ key: environment.biometricsEmailKey });
};

export const saveBiometricsEmail = async (email: string) => {
  if (!Capacitor.isPluginAvailable('Preferences')) {
    return;
  }

  if (email) {
    const cryptedEmail = await CryptoService.encrypt(email, environment.cryptoKey, 'saveBiometricsEmail');
    await Preferences.set({
      key: environment.biometricsEmailKey,
      value: cryptedEmail,
    });
  }
};

export const checkAcceptPolicy = (authResult: AuthResult, history: History) => {
  if (!authResult.waiver) {
    history.push('/accepted_waiver');
  } else if (!authResult.privacy) {
    history.push('/privacy_policy');
  } else if (history && (history.location.pathname === '/reset-password' || history.location.pathname === '/login')) {
    history.push('/tabs/home');
  }
};

export const setOnboard = () => {
  localStorage.setItem('onboard', 'true');
};

export const logout = async (dispatch: AppDispatch, is401?: boolean) => {
  try {
    await dispatch(removeVaultValue(true));
    await removeWebUser();
    localStorage.removeItem('policySet');
    resetRaygunUser();
    const { removeAllOfflineData } = await import('../../services/offlineJournal.service');
    await removeAllOfflineData();
    dispatch(clearAllStates());
  } catch (error) {
    logError(error, ['Login Service', 'logout']);
  }

  if (!isNative() && is401) {
    dispatch(showTokenTimeOut());
  }
};

export const saveRememberEmail = async (email: string) => {
  if (!Capacitor.isPluginAvailable('Preferences')) {
    return;
  }

  const encrypted = await CryptoService.encrypt(email, environment.cryptoKey, 'saveRememberEmail');
  await Preferences.set({
    key: environment.remeberEmailKey,
    value: encrypted,
  });
};

export const getRememberEmail = async (): Promise<string | null> => {
  if (!Capacitor.isPluginAvailable('Preferences')) {
    return null;
  }

  const encryptedEmail = await Preferences.get({ key: environment.remeberEmailKey });

  if (encryptedEmail.value) {
    const decrypted = await CryptoService.decrypt(encryptedEmail.value, environment.cryptoKey, 'getRememberEmail');
    return decrypted;
  } else {
    return null;
  }
};

export const removeRememberEmail = async () => {
  if (!Capacitor.isPluginAvailable('Preferences')) {
    return;
  }

  await Preferences.remove({ key: environment.remeberEmailKey });
};
