import cloneDeep from 'lodash/cloneDeep';

import { JournalCardQuestion } from '../../components/JournalCardQuestions/cardQuestions';
import { JournalQuestionNumber } from '../../components/JournalQuestionNumber/JournalQuestionNumber';
import { JournalRangeSlider } from '../../components/JournalRangeSlider/JournalRangeSlider';
import { TEAJournalRangeSlider } from '../../components/TEAJournalRangeSlider/TEAJournalRangeSlider';
import type { AppDispatch } from '../../store';
import type { Mood, PopUpAlias, Question, QuestionsSubmission } from './journalSlice';
import { JOURNAL_ALERT, showAlert } from './journalSlice';

export const journalRender = (question: Question) => {
  switch (question.type) {
    case 'single_select' || 'mutli_select':
      return <JournalCardQuestion questionId={question.product_field_id} />;
    case '0_30_number':
      return <JournalQuestionNumber questionId={question.product_field_id} />;
    case 'slider_0_10':
      return <JournalRangeSlider questionId={question.product_field_id} />;
    case 'TEA_1_10':
      return <TEAJournalRangeSlider questionId={question.product_field_id} />;
    default:
      return <JournalCardQuestion questionId={question.product_field_id} />;
  }
};

export const checkSelectedValue = (questions: Question[], slideIndex: number, dispatch: AppDispatch): boolean => {
  const question = questions[slideIndex];

  if (
    // Check that there is a response
    question.type === 'single_select' &&
    (question.selected === null || question.selected === undefined)
  ) {
    dispatch(showAlert(JOURNAL_ALERT.EMPTY));
    return false;
  } else if (
    // Check that not out of range (for 0 - 30 number selector)
    question.type === '0_30_number' &&
    (isNaN(question.selected as number) ||
      question.selected === null ||
      question.selected === undefined ||
      (question.selected as number) > 30 ||
      (question.selected as number) < 0)
  ) {
    dispatch(showAlert(JOURNAL_ALERT.INVALID_NUMBER));
    return false;
  } else if (
    // Check that not an empty array (for multi_seelct)
    question.type === 'mutli_select' &&
    (!question.selected || !Array.isArray(question.selected) || (question.selected as number[]).length === 0)
  ) {
    dispatch(showAlert(JOURNAL_ALERT.EMPTY));
    return false;
  } else {
    return true;
  }
};

export const findPopupContent = (popUps: PopUpAlias[], selectedValue: number) => {
  return popUps.find(({ values }) => values.includes(selectedValue));
};

export const findSlideByJournalFieldID = (questions: Question[], product_field_id: string): number => {
  return questions.findIndex((q) => q.product_field_id === product_field_id);
};

export const setQuestionsToDefaults = (questions: Question[]): Question[] => {
  if (questions.length > 0) {
    questions.forEach((q) => {
      if (q.type === 'single_select' || q.type === '0_30_number') {
        q.selected = null;
      } else if (q.type === 'mutli_select') {
        q.selected = [];
      } else if (q.type === 'TEA_1_10') {
        q.remarks = '';
        q.selected = null;
      }
    });
  }
  return questions;
};

export const saveFailedJournals = async (
  questionsSubmission: QuestionsSubmission,
  moods: Mood[],
  use_moods: boolean,
) => {
  const offlineJournal = cloneDeep(questionsSubmission);
  offlineJournal.offline = true;
  if (use_moods) {
    const moodList = moods;
    const mood = moodList ? moodList.find((element) => element.mood_id === questionsSubmission.mood_id) : null;
    const moodAlias = mood && mood.mood_alias ? mood.mood_alias : '';
    offlineJournal.mood_alias = moodAlias;
  }
  const { saveOfflineJournal } = await import('../../services/offlineJournal.service');
  await saveOfflineJournal(offlineJournal);
};

export const findSlideIndex = (questions: Question[], order: number, product_id: string) => {
  // Check to see if this order represents the end of the product
  const current_product_questions = questions.filter((element) => element.product_id === product_id);
  const max_order_in_current_product = Math.max(...current_product_questions.map((q) => q.order));
  if (order > max_order_in_current_product) {
    const last_question_in_product = questions.findLastIndex((q) => q.product_id === product_id);
    const this_is_last_product = last_question_in_product === questions.length - 1;
    if (this_is_last_product) {
      return -2; // Go to end of submission
    } else {
      return last_question_in_product + 1; // Go to next product
    }
  }

  return questions.findIndex((element) => element.order === order && element.product_id === product_id);
};
