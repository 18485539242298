import { lazy, Suspense } from 'react';

import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react';
import { IonReactHashRouter } from '@ionic/react-router';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
/* Theme variables */
import './theme/variables.css';
import './App.scss';

import { history } from './history';
import { useInitApp, useVaultDevice } from './hooks';
import Spinner from './spinner';

setupIonicReact({ innerHTMLTemplatesEnabled: true });

const Loader = lazy(() => import('./loader'));
const AppUrlListener = lazy(() => import('./AppUrlListener'));
const AppUpdate = lazy(() => import('./components/AppUpdate/AppUpdate'));
const NoInternetConnection = lazy(() => import('./components/NoInternetConnection/NoInternetConnection'));
const ErrorToast = lazy(() => import('./components/ErrorToast/ErrorToast'));
const ErrorAlert = lazy(() => import('./components/ErrorAlert/ErrorAlert'));
const MiddlewareRouter = lazy(() => import('./routes/MiddlewareRouter'));

const App = () => {
  useInitApp();
  useVaultDevice();

  return (
    <IonApp>
      <IonReactHashRouter history={history}>
        <IonRouterOutlet>
          <Suspense fallback={<Spinner />}>
            <Loader />
            <AppUrlListener />
            <AppUpdate />
            <NoInternetConnection />
            <ErrorToast />
            <ErrorAlert />
          </Suspense>
        </IonRouterOutlet>
        <IonRouterOutlet>
          <Suspense fallback={<Spinner />}>
            <MiddlewareRouter />
          </Suspense>
        </IonRouterOutlet>
      </IonReactHashRouter>
    </IonApp>
  );
};

export default App;
