import type { AxiosError } from 'axios';
import axios from 'axios';

import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import type { EnglishTranslation } from '../../i18n';
import { APIStatus } from '../../models/api';
import type { ErrorMessage } from '../../models/error';
import type { AsyncThunkConfig } from '../../models/slice';
import { postJournalLocation } from '../../services/location.service';
import { RaygunErrorHandlerService } from '../../services/raygun.service';
import type { Badge } from '../Badges/BadgesSlice';
import { fetchStreaks } from '../Home/streakSlice';
import { refreshToken } from '../Login/loginSlice';
import { findSlideIndex, saveFailedJournals, setQuestionsToDefaults } from './journal.helper';

const { logError } = RaygunErrorHandlerService();

export type Question = {
  name_alias: string;
  description_alias: string;
  audio_url_alias: keyof EnglishTranslation['Audio_question'];
  product_id: string;
  product_field_id: string;
  values: QuestionValue[];
  order: number;
  next_question: NextQuestion[];
  type: string;
  long_name_alias: keyof EnglishTranslation['fields'];
  popups: PopUpAlias[];
  selected: number | number[] | null;
  selection_time: Date;
  remarks: string | null | undefined;
};

export type PopUpAlias = {
  popup_content_alias: keyof EnglishTranslation['questions'];
  popup_title_alias: keyof EnglishTranslation['questions'];
  values: number[];
};

type NextQuestion = {
  value: number;
  next_question: number;
};

export type QuestionValue = {
  img_url: string;
  icon: string;
  name_alias: keyof EnglishTranslation['fields'];
  value: number;
};

export type Mood = {
  mood_id: string;
  mood_alias: keyof EnglishTranslation['moods'];
};

type QuestionField = {
  selected: number | number[] | null;
  selection_time: Date;
  product_field_id: string;
  remarks: string | null | undefined;
};

export type ScheduledProduct = Journal & {
  due_date: string;
  product_schedule_id: string;
};

export type AlwaysAvailableProduct = {
  icon: string;
  img: string;
  journal_name_alias: keyof EnglishTranslation['journals'];
  journal_short_name_alias: string;
  journal_subtext_alias: keyof EnglishTranslation['journals'];
  product_id: string;
};

type Journal = {
  icon: string;
  product_id: string;
  journal_name_alias: keyof EnglishTranslation['journals'];
  journal_subtext_alias: string;
};

//submit journal
export type QuestionsSubmission = {
  start_timestamp: Date;
  submission_timestamp: Date | null;
  diary: string | null | undefined;
  product_id: string | null;
  fields: QuestionField[];
  mood_id: string | null;
  offline?: boolean;
  mood_text: string | null;
  mood_alias?: string | null;
  product_schedule_id: string | null;
};

export type PopUp = {
  title: `questions.${keyof EnglishTranslation['questions']}`;
  content: `questions.${keyof EnglishTranslation['questions']}`;
};

type JumpQuestion = {
  nextSlideIndex: number | null;
  product_id: string | null;
  journal_field_ids: string[];
};

type SaveJournalResult = {
  earned_badges: Badge[];
  message: string | null;
  success: boolean;
  submission_id: string | null;
};

export enum JOURNAL_COMPONENT {
  IDLE = 'idle',
  MOOD = 'mood',
  QUESTION = 'question',
  NOTES = 'notes',
  SUCCESS_JOURNAL = 'successJournal',
  FAILED_JOURNAL = 'errorJournal',
}

export enum JOURNAL_ALERT {
  EMPTY = 'emptyValue',
  IDLE = 'idle',
  LEAVE_JOURNAL = 'leaveJournal',
  NO_JOURNAL_ENTRY = 'noJournalEntry',
  POP_UP = 'popUp',
  INVALID_NUMBER = 'invalidNumber',
  INVALID_MOOD_TEXT = 'invalidMoodText',
  MAX_LENGTH_REACHED = 'maximumJournalLengthReached',
}

type JournalSliceType = {
  moodApiStatus: APIStatus;
  moods: Mood[];
  questionApiStatus: APIStatus;
  questions: Question[];
  questionsSubmission: QuestionsSubmission;
  currentComponent: JOURNAL_COMPONENT;
  pastComponent: JOURNAL_COMPONENT | null;
  alert: JOURNAL_ALERT;
  popUp: PopUp;
  jumpQuestion: JumpQuestion;
  saveJournalResult: SaveJournalResult;
  offlineSubmissionLocked: boolean;
  scheduledProducts: ScheduledProduct[];
  scheduledProductsApiStatus: APIStatus;
  alwaysAvailableProducts: AlwaysAvailableProduct[];
};

const initialState: JournalSliceType = {
  moodApiStatus: APIStatus.IDLE,
  moods: [],
  questionApiStatus: APIStatus.IDLE,
  questions: [],
  questionsSubmission: {
    start_timestamp: new Date(),
    submission_timestamp: null,
    diary: null,
    product_id: null,
    fields: [],
    mood_id: null,
    mood_text: null,
    mood_alias: null,
    offline: false,
    product_schedule_id: null,
  },
  currentComponent: JOURNAL_COMPONENT.IDLE,
  pastComponent: null,
  alert: JOURNAL_ALERT.IDLE,
  popUp: {} as PopUp,
  jumpQuestion: {
    nextSlideIndex: null,
    product_id: null,
    journal_field_ids: [],
  },
  saveJournalResult: {
    earned_badges: [],
    message: null,
    success: false,
    submission_id: null,
  },
  offlineSubmissionLocked: false,
  scheduledProducts: [],
  scheduledProductsApiStatus: APIStatus.IDLE,
  alwaysAvailableProducts: [],
};

export const getMoods = createAsyncThunk<Mood[], undefined, AsyncThunkConfig>(
  'journal/getMoods',
  async (_, thunkAPI) => {
    try {
      const online = navigator.onLine;
      if (online) {
        const response = (await axios.get('v3_moods')) as Mood[];
        if (response) {
          const { setOfflineMoods } = await import('../../services/offlineJournal.service');
          setOfflineMoods(response);
          return response;
        }
      }
      const { getSavedMoods } = await import('../../services/offlineJournal.service');
      const moods = await getSavedMoods();
      return moods;
    } catch (e) {
      logError(e, ['journalSlice', 'getMoods']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const getQuestions = createAsyncThunk<Question[], string | null, AsyncThunkConfig>(
  'journal/getQuestions',
  async (product_id, thunkAPI) => {
    try {
      const online = navigator.onLine;
      if (online) {
        const response = (await axios.post('v2_question', { product_id })) as Question[];
        if (response) {
          const questions = setQuestionsToDefaults(response);
          const { setOfflineQuestions } = await import('../../services/offlineJournal.service');
          setOfflineQuestions(questions);
          return questions;
        }
      }
      const { getSavedQuestions } = await import('../../services/offlineJournal.service');
      const questions = await getSavedQuestions();
      if (questions && questions.length > 0 && (!product_id || questions[0].product_id === product_id)) {
        return questions;
      }
      return [];
    } catch (e) {
      logError(e, ['journalSlice', 'getQuestions']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const getScheduledProducts = createAsyncThunk<ScheduledProduct[], undefined, AsyncThunkConfig>(
  'journal/getScheduledProducts',
  async (_, thunkAPI) => {
    try {
      const online = navigator.onLine;
      if (online) {
        const response = (await axios.get('v3_scheduled_product')) as ScheduledProduct[];
        if (response) {
          const { setOfflineScheduledProducts } = await import('../../services/offlineJournal.service');
          setOfflineScheduledProducts(response);
          return response;
        }
      }
      const { getOfflineScheduledProducts } = await import('../../services/offlineJournal.service');
      const scheduledProducts = await getOfflineScheduledProducts();
      if (scheduledProducts) {
        return scheduledProducts;
      }
      return [];
    } catch (e) {
      logError(e, ['journalSlice', 'getScheduledProducts']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const saveJournal = createAsyncThunk<SaveJournalResult, QuestionsSubmission, AsyncThunkConfig>(
  'journal/saveJournal',
  async (_, thunkAPI) => {
    const {
      journal: { questionsSubmission, moods },
      login: {
        authResult: { use_location, use_mood },
      },
    } = thunkAPI.getState();
    try {
      const response = (await axios.post('journal_submission', questionsSubmission)) as SaveJournalResult;
      if (response && response.success) {
        if (use_location && response.submission_id) {
          await postJournalLocation(response.submission_id);
        }
        thunkAPI.dispatch(refreshToken({ history: null, isJournaling: false }));
        thunkAPI.dispatch(fetchStreaks());
        return response;
      }
      await saveFailedJournals(questionsSubmission, moods, use_mood);
      const error = response as unknown as ErrorMessage;
      return thunkAPI.rejectWithValue(error);
    } catch (e) {
      const error = e as AxiosError<ErrorMessage>;
      if (!(error.response?.status === 422 && error.response.data.displayText === 'Fields are all empty')) {
        await saveFailedJournals(questionsSubmission, moods, use_mood);
      }
      logError(e, ['journalSlice', 'saveJournal']);
      return thunkAPI.rejectWithValue(error.response?.data);
    }
  },
);

export const submitOfflineJournal = createAsyncThunk<SaveJournalResult, QuestionsSubmission, AsyncThunkConfig>(
  'journal/submitOfflineJournal',
  async (submission, thunkAPI) => {
    try {
      const response = await axios.post('journal_submission', submission);
      return response as unknown as SaveJournalResult;
    } catch (e) {
      logError(e, ['journalSlice', 'submitOfflineJournal']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const getAlwaysAvailableProducts = createAsyncThunk<AlwaysAvailableProduct[], undefined, AsyncThunkConfig>(
  'journal/getAlwaysAvailableProducts',
  async (_, thunkAPI) => {
    try {
      const online = navigator.onLine;
      if (online) {
        const response = (await axios.get('v3_always_available_products')) as AlwaysAvailableProduct[];
        if (response) {
          const { setOfflineAlwaysAvailableProducts } = await import('../../services/offlineJournal.service');
          setOfflineAlwaysAvailableProducts(response);
          return response;
        }
      }
      const { getOfflineAlwaysAvailableProducts } = await import('../../services/offlineJournal.service');
      const products = await getOfflineAlwaysAvailableProducts();
      return products;
    } catch (e) {
      logError(e, ['journalSlice', 'getAlwaysAvailableProducts']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const journalSlice = createSlice({
  name: 'journal',
  initialState,
  reducers: {
    clearJournalState: () => initialState,
    saveMood: (state, action: PayloadAction<string | null>) => {
      state.questionsSubmission.mood_id = action.payload;
    },
    saveMoodText: (state, action: PayloadAction<string | null>) => {
      state.questionsSubmission.mood_text = action.payload;
    },
    saveScheduleId: (state, action: PayloadAction<string>) => {
      state.questionsSubmission.product_schedule_id = action.payload;
    },
    onSingleOptionPressed: (
      state,
      action: PayloadAction<{ product_field_id: string; selected: number | null; selection_time: Date }>,
    ) => {
      const questionIndex = state.questions.findIndex((el) => el.product_field_id === action.payload.product_field_id);
      state.questions[questionIndex].selected = action.payload.selected;
      state.questions[questionIndex].selection_time = action.payload.selection_time;
    },
    onRemarks: (state, action: PayloadAction<{ product_field_id: string; remarks: string | null | undefined }>) => {
      const questionIndex = state.questions.findIndex((el) => el.product_field_id === action.payload.product_field_id);
      state.questions[questionIndex].remarks = action.payload.remarks;
    },
    onMultiOptionPressed: (
      state,
      action: PayloadAction<{ product_field_id: string | null | undefined; selected: number; selection_time: Date }>,
    ) => {
      const questionIndex = state.questions.findIndex((el) => el.product_field_id === action.payload.product_field_id);
      const selected = action.payload.selected as number;
      const selections = state.questions[questionIndex].selected as number[];
      const index = selections.indexOf(selected);
      index === -1 ? selections.push(selected) : selections.splice(index, 1);
      state.questions[questionIndex].selection_time = action.payload.selection_time;
    },
    onSlideLoad: (state) => {
      if (state.questions.length > 0) {
        state.questionsSubmission.start_timestamp = new Date();
        state.questionsSubmission.submission_timestamp = null;
        state.questionsSubmission.fields = [];
      }
    },
    onNextSlideClick: (state, action: PayloadAction<{ product_field_id: string }>) => {
      state.jumpQuestion.journal_field_ids.push(action.payload.product_field_id);
    },
    onPrevSlideClick: (state) => {
      state.jumpQuestion.journal_field_ids.pop();
    },
    setSkip: (state, action: PayloadAction<{ slideIndex: number }>) => {
      const slideIndex = action.payload.slideIndex;
      const currentQuestion = state.questions[slideIndex];
      state.jumpQuestion.product_id = '';
      state.jumpQuestion.nextSlideIndex = null;
      if (currentQuestion.next_question && currentQuestion.next_question.length > 0) {
        currentQuestion.next_question.forEach((element) => {
          if (element.value === currentQuestion.selected) {
            // single select only
            state.jumpQuestion.product_id = currentQuestion.product_id;
            state.jumpQuestion.nextSlideIndex = findSlideIndex(
              state.questions,
              element.next_question,
              currentQuestion.product_id,
            );
            state.questions.forEach((element, index) => {
              //remove all the value if value change
              if (
                index > slideIndex &&
                state.jumpQuestion.nextSlideIndex !== null &&
                index < state.jumpQuestion.nextSlideIndex
              ) {
                if (element.type === 'single_select' || element.type === '0_30_number') {
                  element.selected = null;
                } else if (element.type === 'mutli_select') {
                  element.selected = [];
                }
              }
            });
          }
        });
      }
    },
    prepareSubmit: (state) => {
      state.questions.forEach((element) => {
        state.questionsSubmission.submission_timestamp = new Date();
        state.questionsSubmission.fields.push({
          selected: element.selected,
          product_field_id: element.product_field_id,
          selection_time: element.selection_time,
          remarks: element.remarks,
        });
      });
      state.questionsSubmission.product_id = state.questions[0].product_id;
    },
    onPopUPToShow: (state, action: PayloadAction<PopUp>) => {
      state.popUp = action.payload;
    },
    clearPopUp: (state) => {
      state.popUp = initialState.popUp;
    },
    saveJournalNotes: (state, action: PayloadAction<string | null | undefined>) => {
      state.questionsSubmission.diary = action.payload;
    },
    showComponent: (state, action: PayloadAction<JOURNAL_COMPONENT>) => {
      state.pastComponent = state.currentComponent;
      state.currentComponent = action.payload;
    },
    showAlert: (state, action: PayloadAction<JOURNAL_ALERT>) => {
      state.alert = action.payload;
    },
    setOfflineSubmissionLocked: (state, action: PayloadAction<boolean>) => {
      state.offlineSubmissionLocked = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMoods.pending, (state, _action) => {
        state.moodApiStatus = APIStatus.PENDING;
      })
      .addCase(getMoods.fulfilled, (state, action) => {
        state.moodApiStatus = APIStatus.FULFILLED;
        if (action.payload) {
          state.moods = action.payload;
        }
      })
      .addCase(getMoods.rejected, (state, _action) => {
        state.moodApiStatus = APIStatus.ERROR;
      })
      .addCase(getQuestions.pending, (state, _action) => {
        state.questionApiStatus = APIStatus.PENDING;
      })
      .addCase(getQuestions.fulfilled, (state, action) => {
        state.questionApiStatus = APIStatus.FULFILLED;
        if (action.payload) {
          state.questions = action.payload;
        }
      })
      .addCase(getQuestions.rejected, (state, _action) => {
        state.questionApiStatus = APIStatus.ERROR;
      })
      .addCase(getScheduledProducts.pending, (state, _action) => {
        state.scheduledProductsApiStatus = APIStatus.PENDING;
      })
      .addCase(getScheduledProducts.fulfilled, (state, action) => {
        state.scheduledProductsApiStatus = APIStatus.FULFILLED;
        if (action.payload) {
          state.scheduledProducts = action.payload;
        }
      })
      .addCase(getScheduledProducts.rejected, (state, _action) => {
        state.scheduledProductsApiStatus = APIStatus.ERROR;
      })
      .addCase(saveJournal.fulfilled, (state, action) => {
        if (action.payload) {
          state.saveJournalResult = action.payload;
          state.currentComponent = JOURNAL_COMPONENT.SUCCESS_JOURNAL;
        }
      })
      .addCase(saveJournal.rejected, (state, action) => {
        const payload = action.payload as ErrorMessage;
        if (!(payload?.status === 422 && payload.displayText === 'Fields are all empty')) {
          state.currentComponent = JOURNAL_COMPONENT.FAILED_JOURNAL;
        }
      })
      .addCase(getAlwaysAvailableProducts.fulfilled, (state, action) => {
        state.alwaysAvailableProducts = action.payload;
      });
  },
});

export const {
  saveMood,
  saveMoodText,
  saveScheduleId,
  showComponent,
  showAlert,
  onSingleOptionPressed,
  onNextSlideClick,
  onSlideLoad,
  onPrevSlideClick,
  saveJournalNotes,
  onRemarks,
  onMultiOptionPressed,
  onPopUPToShow,
  clearPopUp,
  setOfflineSubmissionLocked,
  clearJournalState,
  prepareSubmit,
  setSkip,
} = journalSlice.actions;
