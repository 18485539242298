import { useTranslation } from 'react-i18next';

import { IonCol, IonContent, IonGrid, IonInput, IonItem, IonLabel, IonRow, IonText } from '@ionic/react';

import './JournalQuestionNumber.scss';

import { onSingleOptionPressed } from '../../pages/Journal/journalSlice';
import { useAppDispatch, useAppSelector } from '../../store';

export const JournalQuestionNumber = ({ questionId }: { questionId: string }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const question = useAppSelector((state) =>
    state.journal.questions.find((question) => question.product_field_id === questionId),
  );

  return (
    <IonContent className="ion-no-padding no-scroll numberQuestionContent">
      <IonGrid>
        <IonRow>
          <IonCol className="titleTextCol">
            <IonText className="titleText">
              {question ? (
                <span
                  className="smallOrangeTextHTML"
                  dangerouslySetInnerHTML={{
                    __html: t(`fields.${question.long_name_alias}`),
                  }}
                />
              ) : null}
            </IonText>
          </IonCol>
        </IonRow>

        <div className="numberQuestionBody">
          <IonRow className="ion-no-padding">
            <IonItem className="numberQuestionInput ion-no-padding" lines="none" mode="ios">
              <IonLabel position="stacked" className="numberLabel">
                {t('checkInPage.inputLabel')}
              </IonLabel>
              <IonInput
                aria-label="journal question number input"
                mode="ios"
                className="numberInput ion-no-padding"
                type="text"
                maxlength={2}
                inputmode="numeric"
                pattern="[0-9]*"
                value={question?.selected as number}
                onIonInput={(e) => {
                  if (e.detail.value) {
                    if (!/^[0-9]+$/g.test(e.detail.value)) {
                      e.target.value = '';
                      return;
                    } else {
                      const value = parseInt(e.detail.value, 10);
                      const valueToSave = !isNaN(value) ? value : null;
                      dispatch(
                        onSingleOptionPressed({
                          selected: valueToSave,
                          product_field_id: questionId,
                          selection_time: new Date(),
                        }),
                      );
                    }
                  }
                }}
              />
            </IonItem>
          </IonRow>
        </div>
      </IonGrid>
    </IonContent>
  );
};
