import { useCallback, useEffect } from 'react';

import { Capacitor, type PluginListenerHandle } from '@capacitor/core';
import { Device } from '@ionic-enterprise/identity-vault';

import { isNative } from '../helpers/utils.helper';
import { setCanUseBiometrics, setLockType } from '../pages/Login/loginSlice';
import { checkLocationPermission } from '../services/location.service';
import { checkNotificationPermission, upsertDevice } from '../services/notification.service';
import { useAppDispatch, useAppSelector } from '../store';

export const useVaultDevice = () => {
  const dispatch = useAppDispatch();
  const token = useAppSelector((state) => state.login.authResult.token);
  const vault = useAppSelector((state) => state.login.vault);
  const hasToken = Boolean(token);

  const updateDeviceInfo = useCallback(
    async (biometrics_enabled: boolean) => {
      if (hasToken) {
        const biometrics_supported = await Device.isBiometricsSupported();
        const location_enabled = await checkLocationPermission();
        const notification_enabled = await checkNotificationPermission();
        await upsertDevice({ biometrics_supported, biometrics_enabled, location_enabled, notification_enabled });
      }
    },
    [hasToken],
  );

  const updateCanUseBiometrics = useCallback(async () => {
    const isBiometricsEnabled = await Device.isBiometricsEnabled();
    updateDeviceInfo(isBiometricsEnabled);
    dispatch(setCanUseBiometrics(isBiometricsEnabled));
  }, [dispatch, updateDeviceInfo]);

  useEffect(() => {
    if (isNative()) {
      updateCanUseBiometrics();
    }
  }, [updateCanUseBiometrics, hasToken]); // to trigger updates after logout/login

  useEffect(() => {
    let appListener: PluginListenerHandle;

    if (isNative()) {
      (async () => {
        const { App } = await import('@capacitor/app');
        if (Capacitor.isPluginAvailable('App')) {
          appListener = await App.addListener('appStateChange', async ({ isActive }) => {
            if (isActive) {
              await updateCanUseBiometrics();
            }
          });
        }
      })();
    }

    return () => {
      appListener?.remove();
    };
  }, [updateCanUseBiometrics]);

  useEffect(() => {
    if (isNative()) {
      Device.setHideScreenOnBackground(true);
    }
  }, []);

  useEffect(() => {
    if (isNative() && vault?.config?.deviceSecurityType) {
      // sets the lock type after app restart
      dispatch(setLockType(vault.config.deviceSecurityType));
    }
  }, [dispatch, vault?.config?.deviceSecurityType, hasToken]); // to trigger updates after logout/login
};
