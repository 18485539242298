import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { IonChip, IonCol, IonContent, IonGrid, IonLabel, IonRange, IonRow, IonText } from '@ionic/react';

import './JournalRangeSlider.scss';

import { isNative } from '../../helpers/utils.helper';
import { onSingleOptionPressed } from '../../pages/Journal/journalSlice';
import { useAppDispatch, useAppSelector } from '../../store';

export const JournalRangeSlider = ({ questionId }: { questionId: string }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const question = useAppSelector((state) =>
    state.journal.questions.find((question) => question.product_field_id === questionId),
  );

  useEffect(() => {
    if (!question?.selected) {
      dispatch(onSingleOptionPressed({ selected: 0, product_field_id: questionId, selection_time: new Date() }));
    }
  }, [dispatch, question?.selected, questionId]);

  return (
    <IonContent className="ion-no-padding no-scroll rangeSliderContent">
      <IonGrid>
        <IonRow>
          <IonCol className="titleTextCol">
            <IonText className="titleText">
              {question ? (
                <span
                  className="smallOrangeTextHTML"
                  dangerouslySetInnerHTML={{
                    __html: t(`fields.${question.long_name_alias}`),
                  }}
                />
              ) : null}
            </IonText>
          </IonCol>
        </IonRow>
        <IonChip class="rangeSliderValue ion-no-padding" mode="ios" color="primary" outline={true}>
          {question?.selected}
        </IonChip>
        <div className="rangeSliderBody">
          {question?.values.map((questionValue, index) => (
            <IonRow className="ion-padding-horizontal" key={index}>
              <IonRange
                aria-label="journal range change"
                className="rangeSlider"
                mode="ios"
                style={{ marginTop: '3.5vw' }}
                snaps={true}
                min={0}
                max={10}
                step={1}
                value={question?.selected as number}
                onIonChange={async (e) => {
                  dispatch(
                    onSingleOptionPressed({
                      selected: e.detail.value as number,
                      product_field_id: questionId,
                      selection_time: new Date(),
                    }),
                  );
                  if (isNative()) {
                    const { HapticsService } = await import('../../services/haptics.service');
                    await HapticsService.hapticsImpactMedium();
                  }
                }}
              >
                <IonLabel slot="start">0</IonLabel>
                <IonLabel slot="end"> 10</IonLabel>
              </IonRange>
              <br />
              <IonLabel style={{ fontSize: '3.75vw', marginTop: '5vw', lineHeight: '5.5vw' }}>
                <span dangerouslySetInnerHTML={{ __html: t('checkInPage.rangeSliderHelp') }}></span>
              </IonLabel>
            </IonRow>
          ))}
        </div>
      </IonGrid>
    </IonContent>
  );
};
