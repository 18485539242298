import type { AxiosError } from 'axios';
import axios from 'axios';

import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import type { GroupedByMonth } from '../../components/Calendar/calendar.service';
import { formatByMonths, getSelectedMonth, saveSelectedMonth } from '../../components/Calendar/calendar.service';
import type { EnglishTranslation } from '../../i18n';
import type { ErrorMessage } from '../../models/error';
import type { AsyncThunkConfig } from '../../models/slice';
import { RaygunErrorHandlerService } from '../../services/raygun.service';

const { logError } = RaygunErrorHandlerService();

type EntriesSliceType = {
  entries: GroupedByMonth<EntrySubmission | YaleSubmission>[];
  yaleJournals: YaleJournal[];
  selectedMonthEntries: GroupedByMonth<EntrySubmission | YaleSubmission> | undefined;
};

export type EntrySubmission = {
  submission_id: string;
  client_id: string;
  mood_alias: keyof EnglishTranslation['moods'];
  diary: string;
  mood_text: string | null;
  short_name: string;
  name: string;
  created_date: string;
  timestamp: string;
  fields: Field[];
  offline?: boolean;
};

enum YaleProduct {
  Morning = 'a01dbf0b-6867-40ad-b48a-20bde97a95f3',
  Evening = '4152f030-3784-4d62-9cd5-019b00c83372',
}

export type YaleJournal = {
  colour: string;
  product_id: YaleProduct;
  short_name_alias: keyof EnglishTranslation['journals'];
};

export type YaleSubmission = {
  timestamp: string;
  [YaleProduct.Morning]: boolean;
  [YaleProduct.Evening]: boolean;
  offline?: boolean;
};

type Field = {
  field_name_alias: keyof EnglishTranslation['fields'];
  response_alias: keyof EnglishTranslation['fields'];
  response_value: string;
};

type YaleEntriesResponse = {
  journals: YaleJournal[];
  submissions: YaleSubmission[];
};

export const fetchEntries = createAsyncThunk<GroupedByMonth<EntrySubmission>[], undefined, AsyncThunkConfig>(
  'entries/fetchEntries',
  async (_, thunkAPI) => {
    try {
      const online = navigator.onLine;
      let entries: EntrySubmission[] = [];
      if (online) {
        entries = await axios.post('/v3_submission');
      }

      //offline journaling
      const { getSavedJournals } = await import('../../services/offlineJournal.service');
      const journals = (await getSavedJournals()) as unknown as EntrySubmission[];
      if (journals.length > 0) {
        entries.unshift(...journals);
      }

      return formatByMonths(entries);
    } catch (e) {
      logError(e, ['entriesSlice', 'fetchEntries']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const fetchYaleEntries = createAsyncThunk<
  { entries: GroupedByMonth<YaleSubmission>[]; journals: YaleJournal[] },
  undefined,
  AsyncThunkConfig
>('entries/fetchYaleEntries', async (_, thunkAPI) => {
  try {
    const online = navigator.onLine;
    let response: YaleEntriesResponse = {
      journals: [],
      submissions: [],
    };
    if (online) {
      response = await axios.post('/v3_yale_submission');
    }

    //offline journaling
    const { getSavedJournals } = await import('../../services/offlineJournal.service');
    const journals = (await getSavedJournals()) as unknown as YaleSubmission[];
    if (journals.length > 0) {
      response.submissions.unshift(...journals);
    }

    return { entries: formatByMonths(response.submissions), journals: response.journals };
  } catch (e) {
    logError(e, ['entriesSlice', 'fetchYaleEntries']);
    return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
  }
});

const initialState: EntriesSliceType = {
  entries: [],
  yaleJournals: [],
  selectedMonthEntries: undefined,
};

export const entriesSlice = createSlice({
  name: 'entries',
  initialState,
  reducers: {
    updateSelectedMonth(state, action: PayloadAction<string>) {
      saveSelectedMonth(action.payload);
      state.selectedMonthEntries = state.entries.find((e) => e.groupName === action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEntries.fulfilled, (state, action) => {
        state.entries = action.payload;
        state.selectedMonthEntries =
          action.payload.find((e) => e.groupName === getSelectedMonth()) ?? action.payload[0];
      })
      .addCase(fetchYaleEntries.fulfilled, (state, action) => {
        state.yaleJournals = action.payload.journals;
        state.entries = action.payload.entries;
        state.selectedMonthEntries =
          action.payload.entries.find((e) => e.groupName === getSelectedMonth()) ?? action.payload.entries[0];
      });
  },
});

export const { updateSelectedMonth } = entriesSlice.actions;
