import React from 'react';
import type { PostHogConfig } from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import './i18n';

import App from './App';
import { environment } from './environment/environment';
import { AuthPersist } from './routes/AuthPersist';
import { store } from './store';

// https://posthog.com/docs/integrate/client/js#config
const options: Partial<PostHogConfig> = {
  api_host: 'https://app.posthog.com',
  debug: environment.environment_deploy === 'local',
  property_denylist: ['email', 'password', 'new_password', 'old_password', 'diary'],
};

const container = document.getElementById('root');
if (container) {
  const root = createRoot(container);
  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <PostHogProvider apiKey={environment.posthogToken} options={options}>
          <AuthPersist>
            <App />
          </AuthPersist>
        </PostHogProvider>
      </Provider>
    </React.StrictMode>,
  );
}
