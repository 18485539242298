import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonGrid,
  IonLabel,
  IonRange,
  IonRow,
  IonText,
  IonTextarea,
} from '@ionic/react';

import './TEAJournalRangeSlider.scss';

import { JOURNAL_ALERT, onRemarks, onSingleOptionPressed, showAlert } from '../../pages/Journal/journalSlice';
import { useAppDispatch, useAppSelector } from '../../store';

const maxLength = 400;

export const TEAJournalRangeSlider = ({ questionId }: { questionId: string }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const question = useAppSelector((state) =>
    state.journal.questions.find((question) => question.product_field_id === questionId),
  );
  const [showPlaceholder, setShowPlaceholder] = useState(true);
  const remarksValue = question?.remarks?.length || 0;

  useEffect(() => {
    if (!question?.selected) {
      dispatch(onSingleOptionPressed({ selected: 1, product_field_id: questionId, selection_time: new Date() }));
    }
  }, [dispatch, question?.selected, questionId]);

  return (
    <IonContent className="ion-no-padding rangeSliderContent">
      <IonGrid>
        <IonRow>
          <IonCol className="titleTextCol">
            <IonText className="titleText">
              {question && (
                <span
                  className="smallOrangeTextHTML"
                  dangerouslySetInnerHTML={{
                    __html: t(`fields.${question.long_name_alias}`),
                  }}
                />
              )}
            </IonText>
          </IonCol>
        </IonRow>
        <IonRow className="ion-justify-content-between">
          <span className="rangeLabelText" style={{ textAlign: 'left', marginLeft: '1vw' }}>
            {t('checkInPage.notWell')}
          </span>
          <span className="rangeLabelText" style={{ marginLeft: '-2vw' }}>
            {t('checkInPage.fair')}
          </span>
          <span className="rangeLabelText" style={{ textAlign: 'right', marginRight: '2vw' }}>
            {t('checkInPage.extremelyWell')}
          </span>
        </IonRow>
        {question?.values.map((questionValue, index) => (
          <IonRow className="ion-padding-horizontal" key={index}>
            <IonRange
              aria-label="TEA journal range change"
              mode="ios"
              snaps={true}
              pin={false}
              min={1}
              max={10}
              step={1}
              value={question?.selected as number}
              onIonChange={(e) =>
                dispatch(
                  onSingleOptionPressed({
                    selected: e.detail.value as number,
                    product_field_id: questionId,
                    selection_time: new Date(),
                  }),
                )
              }
            />
          </IonRow>
        ))}
        <IonRow>
          {Array.from(Array(10).keys()).map((_, i) => (
            <pre key={i} className="rangeLabelNumber">
              {i + 1}
            </pre>
          ))}
        </IonRow>
        <IonRow>
          <IonText color="primary" className="remarksTitle">
            {t('checkInPage.remarks')}:
          </IonText>
        </IonRow>
        <IonRow>
          <IonCard className="remarksCard">
            <IonCardContent>
              <div className={showPlaceholder && !question?.remarks ? 'placeholder' : 'placeholder-hide'}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: t('checkInPage.noteplaceholderText').substring(
                      0,
                      t('checkInPage.noteplaceholderText').indexOf('<br/>'),
                    ),
                  }}
                ></span>
              </div>
              <IonTextarea
                aria-label="TEA journal remarks text input"
                autocapitalize="on"
                autoGrow={true}
                maxlength={maxLength}
                wrap="soft"
                className="ph-no-capture remarks_text_entry"
                value={question?.remarks}
                onIonInput={(e) => {
                  e.preventDefault();
                  if (e.detail.value?.length === maxLength) {
                    dispatch(showAlert(JOURNAL_ALERT.MAX_LENGTH_REACHED));
                  }
                  dispatch(
                    onRemarks({
                      product_field_id: questionId,
                      remarks: e.detail.value,
                    }),
                  );
                }}
                onIonFocus={() => {
                  setShowPlaceholder(false);
                }}
                onIonBlur={() => {
                  if (question?.remarks) {
                    setShowPlaceholder(false);
                  } else {
                    setShowPlaceholder(true);
                  }
                }}
              ></IonTextarea>
            </IonCardContent>
          </IonCard>
        </IonRow>
        <p style={{ marginBottom: '2vh' }} className="characterCount ion-text-end">
          <IonLabel color="medium">
            {remarksValue} / {maxLength}
          </IonLabel>
        </p>
      </IonGrid>
    </IonContent>
  );
};
