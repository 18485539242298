import type { History } from 'history';
import { jwtDecode } from 'jwt-decode';

import { Capacitor } from '@capacitor/core';
import { isPlatform } from '@ionic/react';
import type { VaultError } from '@ionic-enterprise/identity-vault';
import { DeviceSecurityType, VaultErrorCodes } from '@ionic-enterprise/identity-vault';
import type { VaultInterface } from '@ionic-enterprise/identity-vault/dist/typings/VaultInterface';

import { environment } from '../../environment/environment';
import { isNative } from '../../helpers/utils.helper';
import { APIStatus } from '../../models/api';
import type { RaygunUser } from '../../services/raygun.service';
import { RaygunErrorHandlerService } from '../../services/raygun.service';
import type { AppDispatch } from '../../store';
import { getLanguage, saveLanguagePreference } from '../Preferences/languageSlice';
import { showLoading } from '../tabs/tabsSlice';
import { saveWebUser } from './Login.service';
import type { AuthResult, Login, TokenValue, UserOrganization } from './loginSlice';
import {
  setApiStatusAndTextError,
  setDeviceInfo,
  setLogin,
  setNotificationUrl,
  updateAuth,
  updateVaultLockType,
} from './loginSlice';

const { logError, setRaygunUser } = RaygunErrorHandlerService();

export const emailValidation =
  /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const decodeToken = (user: AuthResult) => {
  try {
    if (user?.token) {
      return jwtDecode<TokenValue>(user.token);
    }
  } catch (error) {
    logError(error, ['login.helper', 'decodeToken']);
  }
};

export const getUserOrganization = (user: AuthResult): UserOrganization | undefined => {
  const decodedToken = decodeToken(user);
  if (decodedToken) {
    return decodedToken.organization_id as UserOrganization;
  }
};

export const getRegion = (user: AuthResult) => {
  const decodedToken = decodeToken(user);
  if (decodedToken) {
    if (decodedToken.region === 'us-prod') {
      return 'US';
    }
  }
  return 'CA';
};

export const setUserRaygun = (user: AuthResult) => {
  const decodedToken = decodeToken(user);
  if (decodedToken) {
    const raygunUser: RaygunUser = {
      role: decodedToken.role,
      user_id: decodedToken.user_id,
      organization_id: decodedToken.organization_id,
      region: getRegion(user),
    };
    setRaygunUser(raygunUser);
  }
};

export const updateUser = async (
  user: AuthResult,
  vault: VaultInterface | null,
  dispatch: AppDispatch,
  storeAuth: boolean,
) => {
  if (storeAuth) {
    if (isNative()) {
      try {
        await vault?.setValue(environment.vaultKey, user);
      } catch (e) {
        const error = e as unknown as VaultError;
        logError(error, [
          'login.helper',
          'updateUser',
          'VaultError',
          `code: ${error.code}`,
          `message: ${error.message}`,
        ]);
        if (
          [
            VaultErrorCodes.BiometricsNotEnabled,
            VaultErrorCodes.InvalidatedCredential,
            VaultErrorCodes.SecurityNotAvailable,
          ].includes(error.code)
        ) {
          // To handle error for Android when user disabled device biometrics
          await dispatch(updateVaultLockType(DeviceSecurityType.None));
          await vault?.setValue(environment.vaultKey, user);
        }
      }
    } else {
      await saveWebUser(user);
    }
  }

  setUserRaygun(user);
  dispatch(updateAuth(user));
};

export const handleSuccessfulLogin = async (
  authResult: AuthResult,
  vault: VaultInterface | null,
  notificationUrl: string,
  dispatch: AppDispatch,
  history: History,
  login: Login,
  biometrics: boolean,
) => {
  await updateUser(authResult, vault, dispatch, !authResult.temporary);
  const lng = getLanguage();
  if (lng) {
    await dispatch(saveLanguagePreference(lng));
  }

  if (isNative()) {
    const { Device } = await import('@capacitor/device');
    if (Capacitor.isPluginAvailable('Device')) {
      const info = await Device.getInfo();
      dispatch(setDeviceInfo(info));
    }
    if (!biometrics) {
      dispatch(setLogin(login));
    }
  }

  if (authResult.temporary) {
    dispatch(showLoading(false));
    history.push('/reset-password');
  } else {
    if (isNative() && isPlatform('ios') && !biometrics) {
      const { SavePassword } = await import('capacitor-ios-autofill-save-password');
      await SavePassword.promptDialog({
        username: login.email,
        password: login.password,
      });
    }
    if (!authResult.waiver) {
      dispatch(showLoading(false));
      history.push('/accepted_waiver');
    } else if (!authResult.privacy) {
      dispatch(showLoading(false));
      history.push('/privacy_policy');
    } else {
      if (notificationUrl) {
        history.push(notificationUrl);
        dispatch(setNotificationUrl(''));
      } else {
        history.push('/tabs/home');
      }
    }
  }
};

export const handleLogin = async (
  responses: [[AuthResult], [AuthResult]] | undefined,
  vault: VaultInterface | null,
  notificationUrl: string,
  dispatch: AppDispatch,
  history: History,
  login: Login,
  biometrics: boolean,
) => {
  const authResult1 = responses?.[0]?.[0];
  const authResult2 = responses?.[1]?.[0];

  if (authResult1?.token) {
    handleSuccessfulLogin(authResult1, vault, notificationUrl, dispatch, history, login, biometrics);
  } else if (authResult2?.token) {
    handleSuccessfulLogin(authResult2, vault, notificationUrl, dispatch, history, login, biometrics);
  } else if (authResult1?.expired || authResult2?.expired) {
    dispatch(showLoading(false));
    dispatch(setApiStatusAndTextError({ loginApiStatus: APIStatus.EXPIRED }));
  } else if (authResult1?.account_status === 'archived' || authResult2?.account_status === 'archived') {
    dispatch(showLoading(false));
    dispatch(
      setApiStatusAndTextError({ loginApiStatus: APIStatus.FAILED, errorTextAlias: 'loginPage.loginArchivedClient' }),
    );
  } else {
    dispatch(showLoading(false));
    dispatch(
      setApiStatusAndTextError({
        loginApiStatus: APIStatus.FAILED,
        errorTextAlias: 'loginPage.loginErrorMessage',
      }),
    );
  }
};
