import axios from 'axios';

import { Capacitor } from '@capacitor/core';
import type { Position } from '@capacitor/geolocation';

import { isNative } from '../helpers/utils.helper';
import { getUsedLocation, setUsedLocation } from '../pages/Permissions/permissions.service';
import { RaygunErrorHandlerService } from './raygun.service';

const { logError } = RaygunErrorHandlerService();

type Location = {
  speed: number | null;
  latitude: number;
  longitude: number;
  bearing: null;
  location_type: string;
  accuracy: number;
  heading: number | null;
  altitude: number | null;
  altitude_accuracy: number | null | undefined;
  time_in: number;
  submission_id: string;
};

const sendLocation = (location: GeolocationPosition | Position, submission_id: string) => {
  if (location) {
    const data: Location = {
      speed: location.coords.speed,
      latitude: location.coords.latitude,
      longitude: location.coords.longitude,
      bearing: null,
      accuracy: location.coords.accuracy,
      heading: location.coords.heading,
      altitude: location.coords.altitude,
      altitude_accuracy: location.coords.altitudeAccuracy,
      time_in: location.timestamp,
      location_type: 'journal_entry',
      submission_id: submission_id,
    };

    try {
      axios.post('v2_location', data);
    } catch (error) {
      logError(error, ['location.service', 'sendLocation']);
    }
  }
};

const getNavigatorGeolocation = (submission_id: string, options: PositionOptions) => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (location) => {
        sendLocation(location, submission_id);
      },
      (error) => {
        logError(error, ['location.service', 'postJournalLocation', 'navigator.geolocation']);
      },
      options,
    );
  }
};

export const postJournalLocation = async (submission_id: string) => {
  const options: PositionOptions = { enableHighAccuracy: true };

  if (isNative() && (await checkLocationPermission())) {
    const { Geolocation } = await import('@capacitor/geolocation');

    if (Capacitor.isPluginAvailable('Geolocation')) {
      try {
        const location = await Geolocation.getCurrentPosition(options);
        sendLocation(location, submission_id);
      } catch (error) {
        getNavigatorGeolocation(submission_id, options);
        logError(error, ['location.service', 'postJournalLocation']);
      }
    } else {
      getNavigatorGeolocation(submission_id, options);
    }
  }
};

export const checkLocationPermission = async (): Promise<boolean> => {
  if (!getUsedLocation()) {
    return Promise.resolve(false);
  }

  let state: PermissionState | undefined;
  try {
    if (isNative()) {
      const { Geolocation } = await import('@capacitor/geolocation');

      if (Capacitor.isPluginAvailable('Geolocation')) {
        const nativeStatus = await Geolocation.checkPermissions();
        state = nativeStatus.location as PermissionState;
      } else if (navigator.permissions && navigator.permissions.query) {
        const status = await navigator.permissions.query({ name: 'geolocation' });
        state = status.state;
      } else {
        state = undefined;
      }
    }
  } catch (error) {
    state = undefined;
    logError(error, ['location.service', 'checkLocationPermission']);
  }

  return state === 'granted';
};

export const initLocation = async () => {
  if (isNative()) {
    const { Geolocation } = await import('@capacitor/geolocation');

    if (Capacitor.isPluginAvailable('Geolocation')) {
      await Geolocation.requestPermissions();
      setUsedLocation();
    } else if (navigator.geolocation?.getCurrentPosition) {
      navigator.geolocation.getCurrentPosition(
        (_location) => {
          setUsedLocation();
        },
        (error) => {
          logError(error, ['location.service', 'initLocation']);
        },
      );
    }
  }
};
