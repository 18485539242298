import type { AxiosError } from 'axios';
import axios from 'axios';

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import type { ErrorMessage } from '../../models/error';
import type { AsyncThunkConfig } from '../../models/slice';
import { RaygunErrorHandlerService } from '../../services/raygun.service';
import { getLanguage } from '../Preferences/languageSlice';
import { showLoading } from '../tabs/tabsSlice';

const { logError } = RaygunErrorHandlerService();

type Copy = {
  copy: string;
  id: string;
  name: string;
  alias: string;
  version: number;
};

export type AcknowledgeWaiver = {
  type: string;
  content: string;
  version: number;
};

type WaiverSliceType = {
  loading: boolean;
  copy: Copy[];
  copyAvailable: boolean;
};

export const Alias = {
  terms: 'terms_and_waiver_mobile',
  privacy: 'policy_mobile',
  accepted_waiver: 'waiver_mobile',
};

export const fetchCopy = createAsyncThunk<Copy[], string, AsyncThunkConfig>(
  'waiver/fetchCopy',
  async (alias, thunkAPI) => {
    try {
      thunkAPI.dispatch(showLoading(true));
      const response = await axios.post('/v3_resource', { alias, language_code: getLanguage() });
      thunkAPI.dispatch(showLoading(false));
      return response as unknown as Copy[];
    } catch (e) {
      logError(e, ['waiverSlice', 'fetchCopy']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const setAcknowledge = createAsyncThunk<boolean, AcknowledgeWaiver, AsyncThunkConfig>(
  'waiver/setAcknowledge',
  async (waiver, thunkAPI) => {
    try {
      const response = await axios.post('/v2_acknowledge', waiver);
      return response as unknown as boolean;
    } catch (e) {
      logError(e, ['waiverSlice', 'setAcknowledge']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

const initialState: WaiverSliceType = {
  loading: false,
  copy: [],
  copyAvailable: false,
};

export const waiverSlice = createSlice({
  name: 'waiver',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCopy.pending, (state, _action) => {
        state.loading = true;
      })
      .addCase(fetchCopy.fulfilled, (state, action) => {
        state.copy = action.payload;
        if (state.copy) {
          if (state.copy.length !== 0) {
            state.copyAvailable = true;
          } else {
            logError('Someone seems to have logged in from an account with an organization with no copy set.', [
              'waiverSlice',
              'fetchCopy',
            ]);
          }
        }
        state.loading = false;
      })
      .addCase(fetchCopy.rejected, (state, _action) => {
        state.loading = false;
      })
      .addCase(setAcknowledge.pending, (state, _action) => {
        state.loading = true;
      })
      .addCase(setAcknowledge.fulfilled, (state, _action) => {
        state.loading = false;
      })
      .addCase(setAcknowledge.rejected, (state, _action) => {
        state.loading = false;
      });
  },
});

// export const {} = waiverSlice.actions;
