import type { AxiosError } from 'axios';
import axios from 'axios';

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import type { ErrorMessage } from '../../models/error';
import type { AsyncThunkConfig } from '../../models/slice';
import { RaygunErrorHandlerService } from '../../services/raygun.service';
import { refreshToken } from '../Login/loginSlice';
import { getLanguage } from '../Preferences/languageSlice';

const { logError } = RaygunErrorHandlerService();

type ConsentFormText = {
  copy: string;
  id: string;
  name: string;
  alias: string;
  version: number;
};

type PatientConsentFormSliceType = {
  consentFormText: ConsentFormText[];
};

export const Alias = {
  initial: 'intial_consent_text',
  permission_not_granted: 'preferencesPage_consent_not_granted_text',
  permission_granted: 'preferencesPage_consent_granted_text',
} as const;

type AliasType = typeof Alias;

export const fetchPatientConsentFormCopy = createAsyncThunk<
  ConsentFormText[],
  AliasType[keyof AliasType],
  AsyncThunkConfig
>('patientConsentForm/fetchPatientConsentFormCopy', async (alias, thunkAPI) => {
  try {
    const response = (await axios.post('v3_patient_concent_form_copy', {
      alias,
      language_code: getLanguage(),
    })) as ConsentFormText[];
    return response;
  } catch (e) {
    logError(e, ['patientConsentFormSlice', 'fetchPatientConsentFormCopy']);
    return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
  }
});

export const setPatientConsentFormAcknowledge = createAsyncThunk<boolean, boolean, AsyncThunkConfig>(
  'patientConsentForm/setPatientConsentFormAcknowledge',
  async (acknowledgement, thunkAPI) => {
    try {
      const response = (await axios.post('v2_patient_concent_form_acknowledge', { acknowledgement })) as boolean;
      if (response) {
        await thunkAPI.dispatch(refreshToken({ history: null, isJournaling: false }));
      }
      return response;
    } catch (e) {
      logError(e, ['patientConsentFormSlice', 'setPatientConsentFormAcknowledge']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

const initialState: PatientConsentFormSliceType = {
  consentFormText: [],
};

export const patientConsentFormSlice = createSlice({
  name: 'patientConsentForm',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPatientConsentFormCopy.fulfilled, (state, action) => {
      state.consentFormText = action.payload;
    });
  },
});

// export const {} = patientConsentFormSlice.actions;
