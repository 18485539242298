import dayjs from 'dayjs';

export const setUserRespondedPermissions = () => {
  localStorage.setItem('user-responded-permissions', 'true');
  setUserRespondedBiometricsPermissions();
};

export const getUserRespondedPermissions = () => {
  return localStorage.getItem('user-responded-permissions') === 'true';
};

export const setUserRespondedBiometricsPermissions = () => {
  localStorage.setItem('user-responded-biometrics-permissions', 'true');
};

export const getUserRespondedBiometricsPermissions = () => {
  return localStorage.getItem('user-responded-biometrics-permissions') === 'true';
};

export const saveNotNowAskForBiometricsLoginDate = () => {
  localStorage.setItem('not-now-ask-for-biometrics-login-date', dayjs().format('YYYY-MM-DD'));
};

export const getAskForBiometricsLogin = () => {
  const date = localStorage.getItem('not-now-ask-for-biometrics-login-date');
  if (!date) {
    return true;
  } else {
    return dayjs(date).isBefore(dayjs().add(-3, 'months'));
  }
};

export const removeNotNowAskForBiometricsLoginDate = () => {
  localStorage.removeItem('not-now-ask-for-biometrics-login-date');
};

export const getIsBeforeAndroid13 = (osVersion: string | undefined) => {
  if (!osVersion) {
    return;
  }

  const [mainVersion] = osVersion.split('.');
  return Number(mainVersion) < 13;
};

export const setUsedNotification = () => {
  return localStorage.setItem('used-notification', 'true');
};

export const getUsedNotification = (): boolean => {
  return localStorage.getItem('used-notification') === 'true';
};

export const setUsedLocation = () => {
  return localStorage.setItem('used-location', 'true');
};

export const getUsedLocation = (): boolean => {
  return localStorage.getItem('used-location') === 'true';
};
